<template>
    <div class="big-header">
        <div class="big-header__wrapper">
            <Header/>
            <div class="big-header__top d-flex justify-content-between">
                <div class="big-header__desc">
                    <slot name="title"></slot>
                </div>
                <div class="calc">
                    <div class="big-header-calc d-flex flex-column">
                        <div class="big-header-calc__label d-flex justify-content-between align-items-center">
                            <span class="big-header-calc__val">
                                {{ sum | price }} ₽
                            </span>
                            на
                            <span class="big-header-calc__val">
                                {{ days.count }} {{ loanDaysString }}
                            </span>
                        </div>
                        <div class="big-header-calc__mob-label">
                            <span>Сумма</span>
                            <span>{{ sum | price }} ₽</span>
                        </div>
                        <Sum
                            v-model="sum"
                            :showRangeInfo="3"
                        />
                        <div class="big-header-calc__mob-label">
                            <span>Срок</span>
                            <span>{{ days.count }} {{ loanDaysString }}</span>
                        </div>
                        <Days
                            v-model="days"
                        />
                        <div class="d-flex justify-content-center big-header-calc__btn">
                            <base-button link to="primary" mode="yellow">Оформить заявку</base-button>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="benefits d-flex justify-content-center">
                <li class="benefit d-flex align-items-start" v-for="benefit in benefits" :key="benefit.title">
                    <div class="icon d-flex align-items-center justify-content-center">
                        <div class="img img-wrapper d-flex align-items-center justify-content-center">
                            <img src="./assets/benefit-icon.svg" alt="benefit icon">
                        </div>
                    </div>
                    <div class="helper">
                        <span>{{ benefit.title }}</span>
                        <p>{{ benefit.description }}</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Header from '@/components/common/header/Header'
import './big-header.scss'
import price from '@/helpers/string/price'
import term from '@/helpers/string/term'

import contacts from '@/mixins/contacts'
import sbg from '@/mixins/sbg'

export default {
    name: 'BigHeader',
    mixins: [
        contacts,
        sbg
    ],
    data() {
        return {
            days: {
                type: 'days',
                count: 10
            },
            sum: 10000,
            benefits: [
                {
                    title: 'Быстро',
                    description: '5 минут от заявки до денег',
                },
                {
                    title: 'Надежно',
                    description: 'Шифруем и защищаем данные',
                },
                {
                    title: 'Удобно',
                    description: 'Займы без отказов и проверок',
                }
            ]
        };
    },
    computed: {
        loanDaysString() {
            if (this.days.type === 'days') {
                return term(this.days.count)
            }

            return 'недель'
        },
    },
    methods: {
        getLoan() {
            this.$router.push({
                name: 'LoanPrimary'
            })
        }
    },
    components: {
        Days: () => import('@/components/calculators/days/Days'),
        Sum: () => import('@/components/calculators/sum/Sum'),
        Header
    },
    filters: {
        price
    }
}
</script>